export function Footer() {
    return (
        <footer className="bg-dark py-4 mt-auto">
            <div className="container px-5">
                <div className="row align-items-center justify-content-between flex-column flex-sm-row">
                    <div className="col-auto">
                        <span className="small m-0 text-white">Copyright &copy; Christoph Koschel {new Date().getFullYear()}</span>
                    </div>
                    <div className="col-auto">
                        {/*<a className="link-light small" href="#">*/}
                        {/*    Privacy*/}
                        {/*</a>*/}
                        {/*<span className="text-white mx-1">&sdot;</span>*/}
                        {/*<a className="link-light small" href="#">*/}
                        {/*    Privacy*/}
                        {/*</a>*/}
                        {/*<span className="text-white mx-1">&sdot;</span>*/}
                        {/*<a className="link-light small" href="#">*/}
                        {/*    Privacy*/}
                        {/*</a>*/}
                    </div>
                </div>
            </div>
        </footer>
    )
}