import {Button} from "react-bootstrap";

export function Banner() {
    return (
        <div className="bg-dark">
            <div className="container px-5">
                <div className="row gx-5 align-items-center justify-content-center">
                    <div className="col-lg-8 col-xl-7 col-xxl-6">
                        <div className="my-5 text-center text-xl-start">
                            <h1 className="display-5 fw-bolder text-white text-xl-start">
                                Bytelab Studio
                            </h1>
                            <p className="lead fw-normal text-white-50 mb-4">
                                End - End solution for software systems. From frameworks in the developing process to
                                User-Applications, we provided it with our open source toolkits.
                            </p>
                            <Button variant="primary" size="lg" className="px-4 me-sm-3" disabled={true}
                                    title={"In development"} style={{
                                pointerEvents: "auto"
                            }}>
                                Login
                            </Button>
                            {/*<Button variant="outline-light" size="lg" className="px-4" href="/about">*/}
                            {/*    Learn More*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                    <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">

                    </div>
                </div>
            </div>
        </div>
    );
}