import {Envelope} from "react-bootstrap-icons";
import {Button} from "react-bootstrap";
import React, {useState} from "react";
import {sendContactFormData} from "./api";

const emailRegex: RegExp = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);

export function Contact() {
    const [name, setName] = useState("");
    const [nameWrong, setNameWrong] = useState(false);
    const [email, setEmail] = useState("");
    const [emailWrong, setEmailWrong] = useState(false);
    const [subject, setSubject] = useState("");
    const [subjectWrong, setSubjectWrong] = useState(false);
    const [content, setContent] = useState("");
    const [contentWrong, setContentWrong] = useState(false);
    const [finished, setFinished] = useState(false);

    const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (name.length == 0 || !emailRegex.test(email) || subject.length == 0 || content.length == 0) {
            return;
        }

        sendContactFormData(name, email, subject, content);
        setFinished(true);
    }

    return (

        finished ? (
            <div className="py-5 bg-light">
                <div className="container px-5">
                    <div className="bg-light rounded-3 py-5 px-4 px-md-4 mb-5">
                        <div className="text-center">
                            <h1>Thanks for submitting the contact form</h1>
                        </div>
                        <div className="text-center mt-5">
                            <Button href="/" variant="primary" size="lg">
                                Back to the Main-Page
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="py-5 bg-light">
                <div className="container px-5">
                    <div className="bg-light rounded-3 py-5 px-4 px-md-4 mb-5">
                        <div className="text-center mb-5">
                            <div className="bg-primary d-inline-block rounded-3 mb-3 px-2">
                                <Envelope className="text-white" size={50}/>
                            </div>
                            <h1 className="fw-bolder">Get in touch</h1>
                            <p className="lead fw-normal text-muted mb-0">
                                We'd love to hear from you
                            </p>
                        </div>
                        <div className="row gx-5 justify-content-center">
                            <div className="col-lg-8 col-xl-6">
                                <form onSubmit={e => submitForm(e)}>
                                    <div className="form-floating mb-3">
                                        <input className={"form-control " + (nameWrong ? "is-invalid" : "")}
                                               type="text" placeholder="Enter your name..." value={name}
                                               onChange={e => {
                                                   setName(e.target.value);
                                                   setNameWrong(e.target.value.length == 0);
                                               }}/>
                                        <label htmlFor="name">
                                            Full name
                                        </label>
                                        <div className="invalid-feedback">
                                            A name is required.
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input className={"form-control " + (emailWrong ? "is-invalid" : "")}
                                               type="email" placeholder="name@example.com" value={email}
                                               onChange={e => {
                                                   setEmail(e.target.value);
                                                   setEmailWrong(!emailRegex.test(e.target.value));
                                               }}/>
                                        <label htmlFor="email">
                                            Email address
                                        </label>
                                        <div className="invalid-feedback">
                                            Email is not valid.
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input className={"form-control " + (subjectWrong ? "is-invalid" : "")}
                                               type="text" placeholder="Product XXX, Question" value={subject}
                                               onChange={e => {
                                                   setSubject(e.target.value);
                                                   setSubjectWrong(e.target.value.length == 0);
                                               }}/>
                                        <label htmlFor="subject">
                                            Subject
                                        </label>
                                        <div className="invalid-feedback">
                                            A subject is required.
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                    <textarea className={"form-control " + (contentWrong ? "is-invalid" : "")}
                                              placeholder="Enter your message here" maxLength={1000}
                                              style={{height: "10rem"}} value={content} onChange={e => {
                                        setContent(e.target.value);
                                        setContentWrong(e.target.value.length == 0 || e.target.value.length > 1000);
                                    }}></textarea>
                                        <label htmlFor="content">
                                            Message
                                        </label>
                                        <div className="invalid-feedback">
                                            A message is required or is to long. Maximal 1000 characters
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <Button variant="primary" size="lg"
                                                disabled={nameWrong || emailWrong || subjectWrong || contentWrong || name == "" || email == "" || subject == "" || content == ""}
                                                type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}