import {Button} from "react-bootstrap";

export function ContactBanner() {
    return (
        <div className="bg-light d-lg-flex px-5 justify-content-center align-items-center py-5">
            <div className="bg-dark mx-lg-5 py-2 px-4 rounded-2 d-flex flex-column flex-md-row justify-content-between align-items-center gap-5">
                <div>
                    <h2 className="text-light">Contact Us</h2>
                    <p className="text-light">
                        Let's connect! Whether it's a question, idea, or just to say hi, I'm all ears. Reach out anytime!
                    </p>
                </div>
                <Button variant="primary"  href="/contact">
                    Contact Us
                </Button>
            </div>
        </div>
    )
}