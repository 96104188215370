export type Reference = string;

export type OrderBy<T extends {}> = {
    [KEY in keyof T]: "ASC" | "DESC";
}

export interface BaseRequest<T extends {}> {
    code: number;
}

export interface SingleRequest<T extends {}> extends BaseRequest<T> {
    data: [T];
}

export interface MultiRequest<T extends {}> extends BaseRequest<T> {
    data: T[];
}

export interface Resources {
    id: string;
    accessName: string;
    blob: Buffer;
    mimeType: string;
}

export interface Publisher {
    id: string;
    name: string;
    joined: number;
    isExternal: boolean;
    imageURL: string;
}

export interface Language {
    id: string;
    name: string;
    imageURL: string;
    color: string;
}

export interface Project {
    id: string;
    name: string;
    accessName: string;
    developer: string;
    publisher: string;
    wallpaperURL: string;
    detailURL: string;
    repositoryURL: string;
    isFramework: boolean;
}

export interface ProjectDetail {
    id: string;
    projectID: Reference;
    language: Reference;
    percent: number;
}

export interface ProjectRelease {
    id: string;
    projectID: Reference;
    name: string;
    tag: string;
    releaseTextURL: Reference | null;
    windowsX86DownloadURL: string | null;
    windowsX64DownloadURL: string | null;
    windowsARMDownloadURL: string | null;
    linuxX86DownloadURL: string | null;
    linuxX64DownloadURL: string | null;
    linuxARMDownloadURL: string | null;
    linuxDEBDownloadURL: string | null;
    linuxRPMDownloadURL: string | null;
    macX64DownloadURL: string | null;
    macSILICONDownloadURL: string | null;
    androidDownloadURL: string | null;
    iosDownloadURL: string | null;
    sourceCodeDownloadURL: string;
}

export const DOMAIN: string = "https://bytelab.studio:8443";//"http://localhost:8443"

interface Connection {
    domain: string;
    token: string;
}

let connection: Connection;


function makeRequest<T extends BaseRequest<R>, R extends {}>(method: string, url: string, auth: boolean = true, where: Partial<R> | null = null, limit: number = -1, order: Partial<OrderBy<R>> | null = null): T {
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    let formData: any = {}
    xhr.open(method, connection.domain + url, false);
    xhr.setRequestHeader("Content-Type", "application/json");
    if (auth) {
        xhr.setRequestHeader("Authorization", connection.token);
    }
    if (where != null) {
        formData.where = where;
    }
    if (order != null) {
        formData.order = order;
    }
    if (limit != -1) {
        formData.limit = limit;
    }
    if (method.toUpperCase() == "POST") {
        xhr.send(JSON.stringify(formData));
    } else {
        xhr.send();
    }
    return JSON.parse(xhr.responseText);
}

export function connect(): void {
    connection = {
        token: "",
        domain: DOMAIN
    };
    const req = makeRequest("GET", "/act/main-page/token", false);
    if (req.code == 200 && "token" in req) {
        connection.token = req.token as string;
    } else {
        throw "Failed to connect";
    }

}

export function getProjects(frameworks: boolean): Project[] {
    const {code, data} = makeRequest<MultiRequest<Project>, Project>("POST", "/api/select/mainpage.project", true, {
        isFramework: frameworks
    });
    return data;
}

export function getProject(id: string | null, access: string | null): Project {
    if (id != null) {
        const {code, data} = makeRequest<SingleRequest<Project>, Project>("POST", "/api/select/mainpage.project/" + id);
        return data[0];
    }

    if (access == null) {
        throw "ID and Access cannot be null";
    }

    const {code, data} = makeRequest<MultiRequest<Project>, Project>("POST", "/api/select/mainpage.project", true, {
        accessName: access
    }, 1);
    return data[0];
}

export function getProjectDetails(projectID: string, limit: number = -1): ProjectDetail[] {
    const {
        code,
        data
    } = makeRequest<MultiRequest<ProjectDetail>, ProjectDetail>("POST", "/api/select/mainpage.project.detail", true, {
        projectID: projectID
    }, limit, {
        percent: "DESC"
    });
    return data;
}

export function getProjectReleases(projectID: string, limit: number = -1): ProjectRelease[] {
    const {
        code,
        data
    } = makeRequest<MultiRequest<ProjectRelease>, ProjectRelease>("POST", "/api/select/mainpage.project.release", true, {
        projectID: projectID
    }, limit);
    return data;
}

export function getLanguage(id: string): Language {
    const {
        code,
        data
    } = makeRequest<SingleRequest<Language>, Language>("POST", "/api/select/mainpage.language/" + id, true);
    return data[0];
}

export function getInternalMembers(): Publisher[] {
    const {
        code,
        data
    } = makeRequest<MultiRequest<Publisher>, Publisher>("POST", "/api/select/mainpage.publisher", true, {
        isExternal: false
    });

    return data;
}

export function getExternalMembers(): Publisher[] {
    const {
        code,
        data
    } = makeRequest<MultiRequest<Publisher>, Publisher>("POST", "/api/select/mainpage.publisher", true, {
        isExternal: true
    });

    return data;
}

export function getResource(url: string): string {
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    let formData: any = {}
    xhr.open("GET", connection.domain + url, false);
    xhr.send();
    return xhr.responseText;
}

export function sendContactFormData(name: string, email: string, subject: string, message: string): void {
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    let formData: any = {
        data: [{
            name: name,
            email: email,
            subject: subject,
            text: message
        }]
    }
    xhr.open("POST", connection.domain + "/api/insert/mainpage.contact", false);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", connection.token);
    xhr.send(JSON.stringify(formData));
}