import "./Projects.css";
import {Tag} from "./Tag";
import {
    DOMAIN,
    getLanguage,
    getProjectDetails,
    getProjects,
    Language,
    Project,
} from "../api";

export function ProjectItem({name, wallpaperURL, id, accessName}: Project, index: number) {
    const tags: Language[] = getProjectDetails(id, 2).map(block => getLanguage(block.language));
    console.log(tags);
    return (
        <div key={index} className="col-lg-6" onClick={() => window.location.href = "/products/" + accessName}>
            <div className="position-relative mb-5 rounded-3 project-hover shadow-sm" style={{
                width: 600,
                height: 400
            }}>
                <img className="img-fluid rounded-3 mb-3 position-absolute" width={600} height={400} alt=""
                     src={DOMAIN + wallpaperURL}/>
                <div className="position-absolute rounded-3 p-4 mb-3 d-inline-flex justify-content-between flex-column"
                     style={{
                         width: 600,
                         height: 400
                     }}>
                    <h2 className="fw-bolder text-light">{name}</h2>
                    <div className="d-flex">
                        {tags.map((tag, index) => (<Tag key={index}>
                            <img src={DOMAIN + tag.imageURL} alt="" className=""/>
                            {tag.name}
                        </Tag>))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Projects() {
    const projects: Project[] = getProjects(false);
    const frameworks: Project[] = getProjects(true);
    return (
        <div className="py-5 bg-light">
            <div className="container px-5 my-5">
                <div className="text-center">
                    <h1 className="fw-bolder">Our Work</h1>
                </div>
                {projects.length > 0 && <>
                    <div className="text-center mb-5">
                        <p className="lead fw-normal text-muted mb-0">
                            User-End Products
                        </p>
                    </div>
                    <div className="row gx-5">
                        {projects.map((project: Project, index: number) => ProjectItem(project, index))}
                    </div>
                </>}
                {frameworks.length > 0 && <>
                    <div className="text-center mb-5">
                        <p className="lead fw-normal text-muted mb-0">
                            Framework Products
                        </p>
                    </div>
                    <div className="row gx-5">
                        {frameworks.map((project: Project, index: number) => ProjectItem(project, index))}
                    </div>
                </>}
            </div>
        </div>
    );
}