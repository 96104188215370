import React from 'react';
import {Banner} from "./components/Banner";
import {TeamBoard} from "./components/TeamBoard";
import {Projects} from "./components/Projects";
import {ContactBanner} from "./components/ContactBanner";

function Main() {
    return (
        <>
            <Banner/>
            <Projects/>
            <TeamBoard/>
            <ContactBanner/>
        </>
    );
}

export default Main;
