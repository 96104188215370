export function NavBar() {
    return (
        <div className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container px-5">
                <a className="navbar-brand" href="/">
                    <img src={process.env.PUBLIC_URL + "/logo4.png"} alt="Bytelab Studio" height={120}
                         style={{userSelect: "none"}}/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarContent" aria-controls="#navbarContent" aria-expanded="false"
                        aria-label="Toggle Navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" href="/">Hello</a>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </div>
        </div>
    )
}