import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js"

import Main from './Main';
import {NavBar} from "./components/NavBar";
import {Footer} from "./components/Footer";
import {Product} from "./Product";
import {Error} from "./Error";
import {Contact} from "./Contact";
import {connect} from "./api";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
connect()

root.render(
    <React.StrictMode>
        {/*<>*/}
        <NavBar/>
        <div className="bg-light" style={{minHeight: "82.8vh"}}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/products/:access" element={<Product/>}/>
                    <Route path="*" element={<Error/>}/>
                </Routes>
            </BrowserRouter>
        </div>
        <Footer/>
        {/*</>*/}
    </React.StrictMode>
);


