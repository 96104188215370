import {useParams} from "react-router-dom";
import {Tag} from "./components/Tag";
import {
    DOMAIN,
    getLanguage,
    getProject,
    getProjectDetails, getProjectReleases,
    getProjects,
    getResource,
    Language,
    Project,
    ProjectDetail, ProjectRelease
} from "./api";
import {useState} from "react";

const wrapper: (input: string, ...args: any[]) => string = (str: string): string => str.replace(/<script/gi, "&lt;script").replace(/<\/script/, "&lt;/script");

const policy = "trustedTypes" in window ? window.trustedTypes!.createPolicy("docEscape", {
    createHTML: wrapper
}) : {
    createHTML: wrapper
};


interface DownloadMetadata {
    title: string;
    link: string;
}

function Download({title, link}: DownloadMetadata) {
    return (
        <div>
            <a className="link-primary" href={link} download={true}>{title}</a>
        </div>

    );
}

interface PercentFillMetadata {
    language: Language;
    detail: ProjectDetail;
    start: boolean;
    end: boolean;
}

function PercentFill({language, detail, start, end}: PercentFillMetadata) {
    const [visible, setVisible] = useState(false);

    return (
        <div className="position-relative" style={{
            height: 10,
            width: detail.percent + "%",
            backgroundColor: language.color,
            borderTopLeftRadius: start ? "var(--bs-border-radius-xxl)!important" : "",
            borderBottomLeftRadius: start ? "var(--bs-border-radius-xxl)!important" : "",
            borderTopRightRadius: end ? "var(--bs-border-radius-xxl)!important" : "",
            borderBottomRightRadius: end ? "var(--bs-border-radius-xxl)!important" : ""
        }} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
            <div className="position-absolute text-white text-center p-1 rounded-1 bg-dark translate-middle-x start-50"
                 style={{
                     visibility: visible ? "visible" : "hidden",
                     bottom: "125%"
                 }}>
                {language.name}
            </div>
        </div>
    );
}

export function Product() {
    const {access} = useParams();

    if (!access) {
        throw "Product is null";
    }

    const project: Project = getProject(null, access);
    const details: ProjectDetail[] = getProjectDetails(project.id);
    const releases: ProjectRelease[] = getProjectReleases(project.id);


    return (
        <div className="py-5 bg-light">
            <div className="container px-5 my-5 d-flex flex-column align-items-center">
                <div className="pt-5">
                    <h1 className="fw-bolder">{project.name}</h1>
                </div>
                <div className="pt-3">
                    <img className="img-fluid rounded-3" src={DOMAIN + project.wallpaperURL} width={600} height={400}/>
                </div>
                <div className="pt-2 d-flex flex-row flex-wrap justify-content-center w-75">
                    {details.map(detail => getLanguage(detail.language)).sort((a, b) => a.name < b.name ? -1 : 1).map((language, index) =>
                        <Tag className="mb-2" key={index}>
                            <img src={DOMAIN + language.imageURL} alt=""/>
                            {language.name}
                        </Tag>
                    )}
                </div>
                <div className="pt-5">
                    <table className="fs-5">
                        <tbody>
                        <tr>
                            <td><b>Developer:</b></td>
                            <td>{project.developer}</td>
                        </tr>
                        <tr>
                            <td><b>Publisher:</b></td>
                            <td>{project.publisher}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="pt-5">
                    <div dangerouslySetInnerHTML={{
                        __html: policy.createHTML(getResource(project.detailURL))
                    }}/>
                </div>
                {details.length > 0 &&
                    <div className="mt-5 w-100 p-1 bg-dark rounded-5 d-flex flex-row align-items-center gap-1">
                        {details.map((value, index, array) => {
                            let language: Language = getLanguage(value.language);

                            return (<PercentFill key={index} language={language} detail={value} start={index == 0}
                                                 end={index == array.length - 1}/>);
                        })}
                    </div>
                }
                <div className="pt-5 pb-5 w-100" style={releases.length == 0 ? {display: "none"} : undefined}>
                    <h2>Releases</h2>
                    <div className="accordion w-100 pt-2" id="releaseAccordion">
                        {releases.reverse().map((item, index) =>
                            <div className="accordion-item" key={index}>
                                <div className="accordion-header" id={"releaseHeader" + index}>
                                    <button className={"accordion-button " + (index == 0 ? "" : "collapsed")}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={"#releaseBody" + index} aria-expanded="true"
                                            aria-controls="collapseOne">
                                        <div className="d-flex flex-row justify-content-between w-100 pe-3">
                                            <span>{item.name}</span>
                                            <span>{item.tag}</span>
                                        </div>
                                    </button>
                                </div>
                                <div id={"releaseBody" + index}
                                     className={"accordion-collapse collapse " + (index == 0 ? "show" : "")}
                                     aria-labelledby={"#releaseHeader" + index} data-bs-parent="#releaseAccordion">
                                    <div className="accordion-body">
                                        <div dangerouslySetInnerHTML={{
                                            __html: policy.createHTML(item.releaseTextURL ? getResource(item.releaseTextURL) : "")
                                        }}/>
                                        <div className="pt-3 d-flex gap-2 flex-column">
                                            {item.windowsX86DownloadURL ?
                                                <Download title="Windows x86 (32Bit)"
                                                          link={item.windowsX86DownloadURL}/> : ""}
                                            {item.windowsX64DownloadURL ?
                                                <Download title="Windows x64 (64Bit)"
                                                          link={item.windowsX64DownloadURL}/> : ""}
                                            {item.windowsARMDownloadURL ?
                                                <Download title="Windows ARM" link={item.windowsARMDownloadURL}/> : ""}
                                            {item.linuxX86DownloadURL ?
                                                <Download title="Linux x86 (32Bit)"
                                                          link={item.linuxX86DownloadURL}/> : ""}
                                            {item.linuxX64DownloadURL ?
                                                <Download title="Linux x64 (64Bit)"
                                                          link={item.linuxX64DownloadURL}/> : ""}
                                            {item.linuxARMDownloadURL ?
                                                <Download title="Linux ARM" link={item.linuxARMDownloadURL}/> : ""}
                                            {item.linuxDEBDownloadURL ?
                                                <Download title="Linux Debian package"
                                                          link={item.linuxDEBDownloadURL}/> : ""}
                                            {item.linuxRPMDownloadURL ?
                                                <Download title="Linux RPM package"
                                                          link={item.linuxRPMDownloadURL}/> : ""}
                                            {item.macX64DownloadURL ?
                                                <Download title="Mac x64 (64Bit)" link={item.macX64DownloadURL}/> : ""}
                                            {item.macSILICONDownloadURL ?
                                                <Download title="Mac Silicon" link={item.macSILICONDownloadURL}/> : ""}
                                            {item.androidDownloadURL ?
                                                <Download title="Android" link={item.androidDownloadURL}/> : ""}
                                            {item.iosDownloadURL ?
                                                <Download title="IOS" link={item.iosDownloadURL}/> : ""}
                                            {item.sourceCodeDownloadURL ?
                                                <Download title="Source Code" link={item.sourceCodeDownloadURL}/> : ""}
                                        </div>
                                        <div className="text-danger pt-5">
                                            Please be aware that by clicking the provided download links, you will be
                                            downloading files from an external server, which cannot be checked by us.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}