import React from "react";

import "./Tag.css";

interface TagMetadata {
    children: string | React.JSX.Element | (React.JSX.Element | string)[];
    className?: string;
}

export function Tag({children, className}: TagMetadata) {
    return (
        <div className={"small bg-dark rounded-3 d-flex gap-1 text-light py-2 px-2 me-2 shadow-lg tag " + className}>
            {children}
        </div>
    );
}