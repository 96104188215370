import {DOMAIN, getExternalMembers, getInternalMembers, Publisher} from "../api";

export function TeamProfile(profile: Publisher, key: number) {
    return (
        <div className="col mb-5 mb-xl-0" key={key}>
            <div className="text-center">
                <img className="img-fluid rounded-circle mb-4 px-4" alt="..."
                     src={DOMAIN + profile.imageURL} width={150} height={150}/>
                <h5 className="fw-bolder">{profile.name}</h5>
                <div className="fst-italic text-muted">
                    {profile.joined}
                </div>
            </div>
        </div>
    );
}

export function TeamBoard() {
    const members: Publisher[] = getInternalMembers();
    const external: Publisher[] = getExternalMembers();
    return (
        <>
            <div className="py-5 bg-light">
                <div className="container px-5 my-5">
                    <div className="text-center">
                        <h2 className="fw-bolder">Official Members</h2>
                        <p className="lead fw-normal text-muted mb-5">
                            {/*TODO add lead title*/}
                        </p>
                    </div>
                </div>
                <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
                    {members.map((profile, index) => TeamProfile(profile, index))}
                </div>
            </div>
            <div className="py-5 bg-light" style={external.length == 0 ? {display: "none"} : undefined}>
                <div className="container px-5 my-5">
                    <div className="text-center">
                        <h2 className="fw-bolder">External Members</h2>
                        <p className="lead fw-normal text-muted mb-5">
                            {/*TODO add lead title*/}
                        </p>
                    </div>
                </div>
                <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
                    {external.map((profile, index) => TeamProfile(profile, index))}
                </div>
            </div>
        </>
    );
}